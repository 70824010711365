<template>
    <div class="book">
        <div class="content">
            <div style="float: left">
                <img style="width: 3.5rem" src="../assets/images/book.png" alt="">
            </div>
            <div class="form" style="float: left">
                <div class="title">购书赠送智能计算中心使用时长</div>
                <div class="title">新用户注册</div>
                <el-form
                    ref="loginForm"
                    :model="loginForm"
                    :rules="loginRules"
                    autocomplete="off"
                    label-width="1rem"
                    label-position="left">
                    <el-form-item prop="email" label="邮箱" >
                        <el-input
                            ref="user"
                            v-model="loginForm.email"
                            placeholder="请输入邮箱地址"
                            name="user"
                            type="text"
                            tabindex="1"
                            autocomplete="off">
                        </el-input>
                    </el-form-item>
<!--                    <el-form-item prop="password" label="密码">-->
<!--                        <el-input-->
<!--                            v-model="loginForm.password"-->
<!--                            placeholder="请输入密码"-->
<!--                            type="password"-->
<!--                            tabindex="2"-->     
<!--                            autocomplete="off">-->
<!--                        </el-input>-->
<!--                    </el-form-item>-->
                    <el-form-item prop="code" label="激活码" v-if="!isForget">
                        <el-input
                            v-model="loginForm.code"
                            placeholder="请输入激活码"
                            type="text"
                            tabindex="1"
                            autocomplete="off">
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <span class="howToGet" @click="open">如何获得激活码?</span>
                    </el-form-item>
                </el-form>
                <button type="button" class="btn" @click="submit" v-if="!isForget">注册并激活</button>
                <el-button class="btn" @click="forgetPassword" :loading='loading1' style="top:0.16rem;background:#f3b370;border:1px solid #f9c057">{{ isForget ? '确定' : '忘记平台密码'}}</el-button>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import userService from "@/api/api";

export default {
    name: 'book',
    data() {
        const validatecoding = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请输入激活码'));
            } else if (value.length < 16) {
                callback(new Error('请输入正确的购书激活码'));
            } else {
                callback();
            }
        };
        const validateEmail = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请输入邮箱'));
            } else if (value.length < 6) {
                callback(new Error('邮箱位数需大于六位'));
            } else {
                callback();
            }
        };
        return {
            loading: false,
            loading1: false,
            isForget:false,
            loginForm: {
                email: '',
                code: ''
            },
            loginRules: {
                email: [{ required: true, trigger: 'blur', validator: validateEmail }],
                code: [{ required: true, trigger: 'blur', validator: validatecoding }]
            }
        }
    },
    methods: {
        target(url) {
            window.location.href = url
        },
        open() {
            this.$alert('购买《人工智能：寒武纪平台边缘智能实践》可获得一张卡片，刮开卡片的激活码区域即可获得激活码', '信息', {
                confirmButtonText: '确定',
            });
        },
        forgetPassword(){
            if(this.isForget){
                this.loading1 = true
                userService.getPassword(this.loginForm).then(res => {
                    this.loading1 = false
                    if(res.data){
                        this.$message.success('新密码已发送至您的邮箱，请查看')
                        this.isForget = false
                        this.loginForm = {
                            email: '',
                            code: ''
                        }
                    }else{
                        this.$message.warning(res.msg)
                    }
                })
            }else{
                this.isForget = !this.isForget
            }
            
        },
        submit() {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    userService.addbookCodeing(this.loginForm).then(res => {
                        if (res.code == 200) {
                            this.$message.success('注册并激活成功！')
                            this.loginForm = {}
                            this.target('http://paas.extrotec.com:30080')
                        } else {
                            this.$message.warning(res.msg)
                        }
                        this.loading = false;
                    })
                        .catch(err => {
                            this.loading = false;
                        });
                } else {
                    return false;
                }
            });
        }
    }
}
</script>
<style lang="scss">
.el-message {
    font-size: 18px;
}
</style>
<style lang="scss" scoped>
.book {
    width: 100%;
    background-color: #fff!important;
    .content {
        height: 5rem;
        width: 50%;
        margin: 0 auto;
        padding: 1rem 0.2rem 0 1rem;
        .form {
            margin-top: 0.5rem;
            margin-left: 0.5rem;
            .title {
                font-size: 0.2rem;
                font-weight: 700;
                margin-bottom: 0.2rem;
            }
            .howToGet {
                float: right;
                font-size: 0.05rem;
                color: #169BD5;
                margin-top: 0.1rem;
                cursor: pointer;
            }
        }
        .btn {
            width: 99%;
            position: relative;
            letter-spacing: 2px;
            text-transform: uppercase;
            display: inline-block;
            margin-bottom: 0;
            font-weight: 400;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            -ms-touch-action: manipulation;
            touch-action: manipulation;
            cursor: pointer;
            background-image: none;
            border: 1px solid #2e6da4;
            padding: 6px 12px;
            font-size: 14px;
            line-height: 1.42857143;
            border-radius: 4px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            color: #fff;
            background-color: #337ab7;
        }

    }
    .nav {
        background-color: #222;
        border-color: transparent;
        position: fixed;
        top: 0;
        height: 0.5rem;
        width: 100%;
        .container {
            width: 70%;
            margin: 0 auto;
            height: 100%;
            .navbar-header {
                color: #caced2;
                font-size: 0.2rem;
                font-weight: 200;
                height: 100%;
                .index {
                    display: inline-block;
                    height: 0.5rem;
                    line-height: 0.5rem;
                    cursor: pointer;
                }
                .navbar-right {
                    float: right;
                    .right {
                        font-size: 0.15rem;
                        margin-left: 0.2rem;
                        display: inline-block;
                        height: 0.5rem;
                        line-height: 0.5rem;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .bookFooter {
        background-color: #222;
        border-color: transparent;
        position: fixed;
        bottom: 0;
        height: 0.5rem;
        width: 100%;
        .container {
            width: 70%;
            margin: 0 auto;
            height: 100%;
            text-align: center;
            font-size: 0.05rem;
            color: rgba(255, 255, 255, 0.3);
            p {
                display: inline-block;
                height: 0.5rem;
                line-height: 0.5rem;
                font-weight: 100;
                span {
                    cursor: pointer;
                    color: #337ab7;
                }
            }
        }
    }
}

</style>
<style lang="scss">
// .book {
// }
.el-message-box__title {
    font-size: 0.2rem;
    color: #fff;
}
.el-message-box__message p {
    font-size: 0.1rem;
}
.el-message-box {
    border-radius: 0;
    border: none;
}
.el-message-box__header {
    background-color: #4476a7;
}
.el-message-box__headerbtn {
    display: none;
}
</style>
